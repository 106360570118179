<template>
  <div class="text-gray-900">
    <section-content class="">
      <section-header title="CONTACT US" />
      <div
        class="
        px-8 py-4 
        sm:flex 
        sm:justify-around 
        max-w-screen-lg
        lg:mx-auto
      "
      >
        <div class="my-4 flex flex-col">
          <!-- sm flex div -->

          <div>
            <a
              class="flex items-center mb-2 group rounded-full"
              :href="'mailto:' + contactEmail"
            >
              <email-icon
                :size="18"
                class="rounded-full bg-indigo-200 text-main-700 p-2"
              />
              <p class="text-lg ml-2 text-main-800">{{ contactEmail }}</p>
            </a>
            <!--
            <a
              class="flex items-center"
              href="https://www.openstreetmap.org/node/6982142081#map=19/63.43240/10.40382"
          >
          
            <map-icon
                :size="18"
                class="rounded-full bg-indigo-200 text-main-700 p-2"
              />
              <p class="text-lg ml-2 text-main-800">{{ contactAdress }}</p>
            </a>
            -->
          </div>

          <div class="mb-4 mt-6 flex items-center sm:block">
            <div class="">
              <p class="text-lg mb-1">
                Find us on Social Media
              </p>
              <div class="flex">
                <a
                  class="mr-4 flex items-center text-xl py-1 px-1 rounded text-blue-900 hover:bg-blue-100"
                  :href="facebookURL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <facebook-icon :size="32" class="text-blue-700" />
                  acebook
                </a>
                <a
                  class="flex items-center text-xl py-1 rounded text-blue-900 hover:bg-blue-100"
                  :href="linkedinURL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linked
                  <linkedin-icon :size="32" class="text-blue-700" />
                </a>
              </div>
            </div>

            <p
              v-if="processUpdateURL"
              class="text-sm text-center ml-auto mr-10 sm:mx-auto"
            >
              <a :href="processUpdateURL" target="_blank">
                <button
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 sm:mt-20 rounded-full text-xs sm:text-base"
                >
                  Subscribe to our process updates!
                </button>
              </a>
            </p>
          </div>
        </div>

        <!--
        <div>
          <map-wrapper class="h-64 sm:h-64 sm:w-64" />
        </div>
        -->
      </div>
    </section-content>
  </div>
</template>

<script>
import SectionHeader from "../LightSectionHeader.vue";
import EmailIcon from "mdi-vue/Email.vue";
import FacebookIcon from "mdi-vue/Facebook";
import LinkedinIcon from "mdi-vue/Linkedin";
// Commented out unused components
// import MapIcon from "mdi-vue/Map";
// import MapWrapper from "../MapWrapper.vue";

export default {
  name: "ContactSection",
  components: {
    SectionHeader,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
  },
  data() {
    return {
      contactEmail: "contact@metaito.com",
      contactAdress: "Kjøpmannsgata 59, 7011 Trondheim",
      facebookURL: "https://www.facebook.com/factsplat",
      linkedinURL: "https://www.linkedin.com/company/factsplat",
      processUpdateURL: "", //<-- TODO: What do I put here?
    };
  },
};
</script>

<style>
.gmap_canvas {
  overflow: hidden;
  /* background: none !important; */
}
</style>
