<template>
  <div
    class="mx-8 my-4 flex items-center max-w-screen-lg 
        lg:mx-auto"
  >
    <h3 class="font-bold text-lg sm:text-2xl mx-auto">
      {{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: String,
  },
};
</script>

<style></style>
