<template>
  <div class="p-6 w-full h-64 flex items-center">
    <h3 class="text-white font-bold text-3xl sm:text-4xl mx-auto">
      {{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: String
  }
};
</script>

<style></style>
