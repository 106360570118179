<template>
  <section class="pt-24 py-16 bg-gray-50 text-center space-y-24">
    <div class="px-6 md:px-12 lg:px-24">
      <h1 class="text-3xl sm:text-6xl font-extrabold text-white mb-8">
        Hard work ≠ Productivity


      </h1>
 
    </div>
    <section-content class="space-y-12  text-lg">
      <!-- Second Visual Big Text -->
      <p class="text-2xl sm:text-3xl font-bold text-main  mx-auto mb-12">
        <span style="color: #6A5ACD">Productivity</span> = Movement toward goals
      
      </p>
      <p class="text-gray-900 mx-auto max-w-3xl">
        
        We help individuals and organizations achieve more by removing digital roadblocks and focusing on what truly drives progress. We optimize digital work environments, educate, inspire, and ensure you’re prepared to adapt for the future. By making the most of what you have, we empower teams to reach their goals with ease.

      </p>

      <h1 class="text-3xl sm:text-6xl font-extrabold text-gray-900 mb-4">
        We make productivity <span style="color: #6A5ACD">simple</span>
      </h1>


    </section-content>
  </section>
</template>

<script>
import SectionContent from '../SectionContent.vue';
export default {
  components: { SectionContent },
  name: "ProductivitySection",
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>