<template>
  <div>
    <section-header title="ABOUT US" />
    <section-content>
      <div class="m-4 max-w-screen-lg lg:mx-auto">
        <div class="flex flex-col md:flex-row items-center">
          <div class="md:w-1/2 p-4 flex-shrink-0">
            <img
              class="rounded-lg shadow-lg w-full"
              src="@/assets/images/metaito-office.jpeg"
              alt="Metaito office"
            />
          </div>
          <div class="md:w-1/2 p-4">
            <h2 class="text-2xl font-bold mb-4 text-main-700">Our Story</h2>
            <p class="mb-4 text-lg">
              Founded in Trondheim, Norway, Metaito started as a bold idea
              between two friends who shared a vision: to simplify the digital
              chaos. Svenn-Helge Vatne and Simen Nordbø Abelsen teamed up to
              build Factsplat — one place to put everything. No more jumping
              between tools, folders, or scattered notes. Just a clean, flexible
              platform where people, teams, and organizations can gather and
              organize what matters.
            </p>
            <p class="mb-4 text-lg">
              Since then, the company has grown with the support of investors,
              accelerators, and incredible partners across education,
              volunteering, and public health. From local communities to global
              ambition, we're not just building software — we are creating
              clarity. Our mission is to help people perform, collaborate, and
              focus on what truly matters, without digital noise getting in the
              way.
            </p>
          </div>
        </div>

        <!-- <div class="mt-16 text-center items-center justify-center flex-wrap">
          <a
            href="https://thehub.io/startups/metaito"
            class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-4 mb-12 inline-block"
          >
            Learn more about us
          </a>
        </div> -->
      </div>
    </section-content>
  </div>
</template>

<script>
import SectionHeader from "../SectionHeader.vue";
import SectionContent from "../SectionContent.vue";

export default {
  name: "AboutSection",
  components: {
    SectionHeader,
    SectionContent,
  },
};
</script>

<style></style>
