<template>
  <div>
    <section-header title="SUPPORTED BY" />
    <div class="pb-24 w-full">
      <div
        class="flex items-center justify-center flex-wrap 
        max-w-screen-lg
        lg:mx-auto"
      >
        <a
          v-for="(img, index) in partnerImages"
          class="m-2 sm:m-4 "
          :href="img.link"
          target="_blank"
          rel="noopener noreferrer"
          :key="index"
        >
          <img
            class="rounded-md bg-white w-32 h-12 md:w-48 md:h-20 object-contain p-1"
            :src="require(`@/assets/images/partners/${img.file}`)"
            :alt="img.altText"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../SectionHeader.vue";

export default {
  name: "PartnerSection",
  components: {
    SectionHeader,
  },
  data() {
    return {
      partnerImages: [
        // { file: "eaSmith.png", altText: "Logo, E. A. Smith" },
        // { file: "engage.png", altText: "Logo, Engage" },
        {
          file: "tLab.png",
          altText: "Logo, T:Lab",
          link: "https://tlab.no/",
        },
        {
          file: "grunderBrakka.png",
          altText: "Logo, Gründerbrakka",
          link: "https://www.grunderbrakka.no/",
        },
        {
          file: "ntnu.png",
          altText: "Logo, NTNU",
          link: "https://www.ntnu.edu/",
        },
        {
          file: "IN.png",
          altText: "Logo, Innovasjon Norge",
          link: "https://www.innovasjonnorge.no/",
        },
        {
          file: "researchCouncil.png",
          altText: "Logo, Research Council",
          link: "https://www.forskningsradet.no/en/",
        },
        {
          file: "sixAM.png",
          altText: "Logo, 6AM",
          link: "https://www.6am.no/",
        },
        {
          file: "spark.png",
          altText: "Logo, Spark",
          link: "https://www.sparkntnu.no/",
        },
        {
          file: "tronderEnergi.png",
          altText: "Logo, Trønderenergi",
          link: "https://tronderenergi.no/",
        },
      ],
    };
  },

  computed: {
    partnerImage() {
      const fileName = this.employee.imgName;

      return require(`@/assets/images/partners/${fileName}`);
    },
  },
};
</script>

<style></style>
