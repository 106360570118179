<template>
  <div class="relative swirlyBackground bg-auto sm:bg-contain">
    <page-header />
    <app-header :scrollY="scrollY" :top="48" />
    <vision-section id="vision" />
    <product-section id="product" />
    <!-- <team-section id="team" /> -->
    <about-section id="about" />
    <partner-section id="partners" />
    <contact-section id="contact" />
    <page-footer />

    <!-- TODO: I was thinking about using fixed position  div for the background swirl to 
          make scaling and stuff work better, 
          but it adds wierd extra space on phones (making it so w-full doesnt work properly) -->
    <!-- <div class="swirlBackground bg-black fixed top-0 left-0 h-full lg:pt-24">
      <img
        class="lg:h-48 "
        src="@/assets/images/metaito_bg_swirl_cropped.png"
        alt=""
      />
    </div> -->
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader.vue";
import AppHeader from "./components/AppHeader.vue";
import VisionSection from "./components/sections/VisionSection.vue";
import ProductSection from "./components/sections/ProductSection.vue";
// import TeamSection from "./components/sections/TeamSection.vue";
import AboutSection from "./components/sections/AboutSection.vue";
import PartnerSection from "./components/sections/PartnerSection.vue";
import ContactSection from "./components/sections/ContactSection.vue";
import PageFooter from "./components/PageFooter.vue";

export default {
  name: "App",
  components: {
    PageHeader,
    AppHeader,
    VisionSection,
    ProductSection,
    // TeamSection,
    AboutSection,
    PartnerSection,
    ContactSection,
    PageFooter,
  },
  data: () => {
    return {
      scrollY: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollY = Math.round(window.scrollY);
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700;800");

html,
body {
  font-family: "Dosis", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  /* overflow-x: hidden; */
}

/* .swirlBackground {
  z-index: -999;
} */

.swirlyBackground {
  /* width: 100%; */
  background-color: black;
  background-image: url(../public/img/metaito_bg_swirl_cropped.png);
  background-repeat: no-repeat;
  /* background-size: auto; */
  background-position: center;
  background-attachment: fixed;
  /* text-align: center;
    height: 90vh;
    margin: 0;
    position: relative; */
}
</style>
