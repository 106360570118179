<template>
  <header
    class="
      w-full h-pageHeader
      flex flex-col items-center justify-center
      "
  >
    <img
      class="mt-12 h-64 w-64"
      src="@/assets/images/logo_cropped.png"
      alt=""
    />
    <div class="p-2 text-center">
      <h1 class="text-gray-100 text-6xl -my-2">METAITO</h1>
      <h4 class="text-gray-100 text-3xl">Together we know.</h4>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
};
</script>

<style>
.h-pageHeader {
  height: 30rem;
}
</style>
