<template>
  <scrollactive
    class="w-full h-12 bg-white text-gray-900 shadow-xs fixed top-0"
    :offset="48"
    active-class="app-btn-active"
  >
    <div class="h-full mx-auto max-w-7xl flex px-3">
      <!-- Left section with logo -->
      <div v-show="fixed" class="h-full flex items-center">
        <img
          class="h-6 w-6 mr-2"
          src="@/assets/images/metaito-black.png"
          alt="Metaito logo"
        />
        <div class="text-lg hidden sm:block">METAITO</div>
      </div>

      <!-- Center/Right section with menu -->
      <div class="flex flex-1 items-center h-full justify-center">
        <a href="#vision" class="scrollactive-item app-btn sm:px-4">Vision</a>
        <a href="#product" class="scrollactive-item app-btn sm:px-4">Product</a>
        <a href="#about" class="scrollactive-item app-btn sm:px-4">About</a>
        <a href="#partners" class="scrollactive-item app-btn sm:px-4"
          >Partners</a
        >
        <a href="#contact" class="scrollactive-item app-btn sm:px-4">Contact</a>
      </div>
    </div>
  </scrollactive>
</template>

<script>
export default {
  data() {
    return {
      fixed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const offsetTop =
        window.pageYOffset || document.documentElement.scrollTop;
      this.fixed = offsetTop > 48; // Change threshold as needed
    },
  },
};
</script>

<style lang="postcss" scoped>
.app-header {
  @extend bg-gray-200;
}
.app-btn {
  @apply flex items-center h-12 text-gray-900 px-2 border-b-2 border-white mr-1;
}
.app-btn-active {
  @apply border-purple-700 outline-none;
}
</style>
