import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/assets/css/tailwind.css";
import VueScrollactive from "vue-scrollactive";

import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader

import SectionContent from "./components/SectionContent.vue";

Vue.use(VueLayers);

Vue.component("section-content", SectionContent);

Vue.use(VueScrollactive);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
