<template>
  <div class="separator-line"></div>
</template>

<script>
export default {
  name: "SeparatorLine",
};
</script>

<style scoped>
.separator-line {
  margin: 4rem auto; /* equivalent to m-16 */
  width: 25%; /* equivalent to w-1/4 */
  height: 1px; /* equivalent to h-px */
  background-color: #9ca3af; /* equivalent to bg-gray-400 */
}
</style>