<template>
  <div>
    <section-header title="PRODUCTS AND SERVICES" />
    <section-content>
      <!-- Service 1: Flexible Web Platform (Factsplat) -->
      <div>
        <!-- First Row -->
        <div
          class="px-6 py-2 sm:grid grid-cols-2 gap-4 max-w-screen-lg lg:mx-auto text-lg"
        >
          <!-- Column 1: Image -->
          <div class="sm:w-full sm:m-4">
            <img
              class="block mx-auto"
              src="@/assets/images/product/factsplat-ilustration.png"
              alt="Factsplat illustration - The platform"
            />
          </div>

          <!-- Column 2: Text Description -->
          <div class="sm:w-full sm:m-4 p-2">
            <img
              class="block max-w-sm mb-10"
              src="@/assets/images/logo_factsplat.png"
              alt="Factsplat logo"
            />
            <p class="text-main-800 font-bold mt-4 mb-2 text-xl p-1">
              Simple digital dashboards
            </p>
            <p class="text-gray-900 pl-1 mb-10">
              Connected dashboards for links, tools and documents. Create the
              perfect starting point for any purpose.
            </p>
            <div class="flex justify-end">
              <a
                href="https://factsplat.com/welcome"
                target="_blank"
                rel="noopener noreferrer"
                class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-4 mb-8"
              >
                Try it now
              </a>
            </div>
          </div>
        </div>

        <!-- Second Row -->
        <div
          class="px-6 py-2 sm:grid grid-cols-2 gap-4 items-center max-w-screen-lg lg:mx-auto text-lg"
        >
          <!-- Column 1: YouTube Video -->
          <div class="sm:w-full sm:m-4">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/yBpGR4K-1Xo?si=C3IgWAOo7Mq21LLd"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <!-- Column 2: Text with Button -->
          <div class="sm:w-full sm:m-4">
            <p class="text-main-800 font-bold mt-4 mb-2 text-xl">
              Everything in one place
            </p>
            <p class="text-gray-900">
              We have developed a web-based platform that is easy to use, yet
              extremely powerful. With simple digital dashboards, individuals
              and teams can organize anything in any cloud-based system, from a
              "Financial report Q3 2024" to a messaging thread for a project
              with a client. Factsplat unleashes underutilized potential in the
              cloud. Collect, organize, and share - Make it easy to get to do
              the actual work.
            </p>
            <div class="flex justify-end">
              <a
                href="https://factsplat.com/welcome/organizations"
                target="_blank"
                rel="noopener noreferrer"
                class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-4 mb-12"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Separator Line -->
      <separator-line />

      <!-- ### Consulting Services ### -->
      <div
        class="px-6 py-2 sm:grid grid-cols-2 gap-4 items-center max-w-screen-lg lg:mx-auto text-lg"
      >
        <!-- Left column div -->
        <div class="sm:w-full sm:m-4">
          <img
            class="block w-full mx-auto"
            src="@/assets/images/product/sticos-work-session.png"
            alt="Work session with a client"
          />
        </div>

        <!-- Right column div -->
        <div class="sm:w-full sm:m-4">
          <p class="text-main-800 font-bold mt-4 mb-2 text-xl">
            Consulting Services
          </p>
          <p class="text-gray-900">
            We help organizations increase productivity by optimizing their use
            of digital tools in the cloud. We specialize in simplifying complex
            processes, enabling teams to focus on high-impact tasks without
            getting bogged down by administrative inefficiencies. We help get
            the most out of your chosen tools and your employees. Whether you're
            looking to improve internal communication, organize project assets,
            or better utlizie your digital systems, our team provides tailored
            assistance and solutions that drive results and productivity.
          </p>
          <div class="flex justify-end">
            <a
              href="#contact"
              target="_blank"
              rel="noopener noreferrer"
              class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-6 mb-8"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>

      <!-- Separator Line -->
      <!--
      <separator-line />

   
      <div
        class="px-6 py-2 sm:flex items-center max-w-screen-lg lg:mx-auto text-lg space-y-8 sm:space-y-0 sm:space-x-8"
      >
        <div class="sm:w-1/2 sm:m-4">
          <img
            class="sm:block p-2 mx-auto"
            src="@/assets/images/product/svenn-presenting.png"
            alt="Svenn-Helge (founder) presenting on stage"
          />
        </div>
        <div class="sm:w-1/2 sm:m-4 space-y-6">
          <div>
            <h2 class="text-main-800 font-bold mb-2 text-xl">
              Workshop: Jobb som et geni
            </h2>
            <p class="text-gray-900">
              Bli med på en workshop og oppdag hemmelighetene bak å skape en
              solid digital struktur som forbedrer teamets effektivitet. Dette
              er en workshop for teame som ønsker å samarbeide bedre, få
              kontroll over digitale ressurser og skape en arbeidsflyt som gjør
              hverdagen enklere for alle.
            </p>
            <div class="flex justify-end">
              <a
                href="https://workshop.metaito.com/"
                target="_blank"
                rel="noopener noreferrer"
                class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-4"
              >
                Lær mer
              </a>
            </div>
          </div>

          <div>
            <h2 class="text-main-800 font-bold mb-2 text-xl">
              Workshop: Utnytt KI for strategisk vekst
            </h2>
            <p class="text-gray-900">
              Løs konkrete næringsutfordringer med KI som verktøy og lær hvordan
              KI kan optimalisere drift, styrke beslutningsprosesser og gi
              konkrete resultater. Utforsk praktiske bruksområder for KI og få
              en tydelig strategi for å implementere KI i dag.
            </p>
            <div class="flex justify-end">
              <a
                href="https://workshop.metaito.com/ki"
                target="_blank"
                rel="noopener noreferrer"
                class="px-4 py-2 text-lg bg-main-600 text-gray-100 font-bold rounded-lg mt-4"
              >
                Lær mer
              </a>
            </div>
          </div>

          <div>
            <p class="text-base mt-12">
              <strong
                >Note: Our workshops are currently only available in
                Norwegian.</strong
              >
            </p>
            <p class="text-base">
              <a href="#contact" class="underline">Contact us</a> if you are
              interested in an English workshop or lecture.
            </p>
          </div>
        </div>
        
      </div>
      -->
    </section-content>
  </div>
</template>

<script>
import SectionHeader from "../SectionHeader.vue";
import SeparatorLine from "../shared/SeparatorLine.vue";

export default {
  components: {
    SectionHeader,
    SeparatorLine,
  },
  data() {
    return {
      factsplatDescription:
        "Factsplat builds on the benefits of clustering information by creating a collaboration environment that is adaptable to each individual and organization. It allows a user to organize their digital resources in clear, shareable and customizable dashboards.", //TODO: Unused, written directly into the template
      sellingPoins: [
        ["web-icon", "A personal start page for navigating the internet"],
        ["graph-icon", "A dynamic overview of projects and team resources"],
        [
          "account-group-icon",
          "Simplified administration of business processes",
        ],
        ["account-icon", "Individual adaptation of access to team resources"],
        ["head-check-icon", "Deliberate control of your digital life"],
      ],
      factsplatURL: "https://factsplat.com",
      workshopImages: [
        {
          src:
            "https://factsplat.com/_nuxt/img/admin_and_personal.c4acd2a.avif",
          alt: "Workshop 1",
        },
        {
          src:
            "https://factsplat.com/_nuxt/img/admin_and_personal.c4acd2a.avif",
          alt: "Workshop 2",
        },
        {
          src:
            "https://factsplat.com/_nuxt/img/admin_and_personal.c4acd2a.avif",
          alt: "Workshop 3",
        },
      ],
    };
  },
};
</script>

<style></style>
