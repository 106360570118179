<template>
  <footer class="bg-gray-900 w-full p-2 py-4">
    <div class="flex justify-center items-center">
      <copyright-icon class="text-gray-100 mr-2" :size="20" />
      <p class="text-gray-100 text-sm">
        METAITO AS - 922 183 988
      </p>
    </div>
  </footer>
</template>

<script>
import CopyrightIcon from "mdi-vue/Copyright";
export default {
  name: "PageFooter",
  components: {
    CopyrightIcon
  },
  data() {
    return {
      copyrightText: "METAITO AS - 922 183 988"
    };
  }
};
</script>
